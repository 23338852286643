import React, {useState, useRef, useEffect} from "react";
import ReactDOM from "react-dom/client";
import {Map, GridLayer, TileLayer, CRS} from "leaflet";

import "leaflet/dist/leaflet.css";
import "./index.css";

const App = ({...props}) => {
    useEffect(() => {
        const map = new Map("map", {
            crs: L.CRS.Simple,
            center: [0, 0],
            minZoom: 0,
            maxZoom: 5,
            // maxBounds: [
            //     [0, 0],
            //     [1024, 1024],
            // ],
            attributionControl: false,
        });

        const bounds = [
            [0, 0],
            [1024, 1024],
        ];

        GridLayer.DebugCoords = GridLayer.extend({
            options: {
                tileSize: 64,
            },
            createTile: function (coords) {
                var tile = document.createElement("div");
                tile.style.outline = "1px solid #ffffff";
                tile.style.backgroundColor = "#000000";
                return tile;
            },
        });

        L.gridLayer.debugCoords = function (opts) {
            return new L.GridLayer.DebugCoords(opts);
        };

        map.addLayer(L.gridLayer.debugCoords());

        map.on("moveend zoomend", function () {
            L.gridLayer.debugCoords().redraw();
        });

        L.CRS.Simple.transformation = new L.Transformation(1, 0, 1, 0);
        // const image = L.imageOverlay("https://leafletjs.cn/examples/crs-simple/uqm_map_full.png", bounds).addTo(map);

        map.fitBounds(bounds);

        // GridLayer.DebugCoords = GridLayer.extend({
        //     options: {
        //         tileSize: 256,
        //     },
        //     createTile: function (coords) {
        //         var tile = document.createElement("div");
        //         // if (
        //         //     coords.x / (coords.z + 1) >= 0 &&
        //         //     coords.x / (coords.z + 1) < 4 &&
        //         //     coords.y / (coords.z + 1) < 0 &&
        //         //     coords.y / (coords.z + 1) >= -4
        //         // ) {
        //         tile.innerHTML = [coords.x, coords.y, coords.z].join(", ");
        //         // tile.style.border = "6px solid #212e4b";
        //         tile.style.backgroundColor = "#2d4065";
        //         // }
        //         return tile;
        //     },
        // });
        // L.gridLayer.debugCoords = function (opts) {
        //     return new L.GridLayer.DebugCoords(opts);
        // };
        // map.addLayer(L.gridLayer.debugCoords());

        // const gaodeLayer = new TileLayer("https://webst0{s}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}", {
        //     subdomains: "1234",
        //     tileSize: 256,
        //     noWrap: true,
        // });
        // gaodeLayer.addTo(map);

        // var tiles = new L.GridLayer();
        // tiles.options.tileSize = 64;
        // // tiles.options.tileSize = 256;
        // console.log(tiles.options.tileSize);
        // tiles.createTile = function (coords) {
        //     var tile = L.DomUtil.create("canvas", "leaflet-tile");
        //     var ctx = tile.getContext("2d");
        //     var size = this.getTileSize();
        //     tile.width = size.x;
        //     tile.height = size.y;
        //     // 将切片号乘以切片分辨率，默认为256pixel,得到切片左上角的绝对像素坐标
        //     var nwPoint = coords.scaleBy(size);
        //     // 根据绝对像素坐标，以及缩放层级，反投影得到其经纬度
        //     var nw = map.unproject(nwPoint, coords.z);
        //     //从该切片左上角开始画，画一个切片大小的无填充矩形
        //     ctx.strokeRect(nwPoint.x, nwPoint.y, size.x, size.y);
        //     // ctx.fillStyle = "black";
        //     //x,y,z显示
        //     // ctx.fillText("x: " + coords.x + ", y: " + coords.y + ", zoom: " + coords.z, 50, 60);
        //     //经纬度坐标
        //     // ctx.fillText("lat: " + nw.lat + ", lon: " + nw.lng, 50, 80);
        //     //线的颜色
        //     ctx.strokeStyle = "#000000";
        //     //这是canvans的方法
        //     ctx.beginPath();
        //     ctx.moveTo(0, 0);
        //     ctx.lineTo(size.x - 1, 0);
        //     ctx.lineTo(size.x - 1, size.y - 1);
        //     ctx.lineTo(0, size.y - 1);
        //     ctx.closePath();
        //     ctx.stroke();
        //     return tile;
        // };
        // tiles.addTo(map);

        return () => {};
    }, []);

    return (
        <React.Fragment>
            <div id="map"></div>
        </React.Fragment>
    );
};

let root;
function render(props) {
    const {container} = props;
    root = ReactDOM.createRoot(container ? container.querySelector("#root") : document.querySelector("#root"));
    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
}

render({});
